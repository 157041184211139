<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-content>
          <Calendars />
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import Calendars from "../../components/Tables/Calendars.vue";

export default {
  components: {
    Calendars,
  },
};
</script>

<style lang="scss" scoped></style>
